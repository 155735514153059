<template>
  <v-menu transition="slide-y-transition" left offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="support" icon v-bind="attrs" v-on="on">
        <v-icon> mdi-dots-horizontal </v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <slot name="preActions"></slot>

      <v-list-item @click="viewItem" v-if="view">
        <v-list-item-icon>
          <v-icon color="primary lighten-2" small right> mdi-eye </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Ver </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="editItem" v-if="!noUseEdit">
        <v-list-item-icon>
          <v-icon color="info  lighten-2" small right> mdi-pencil </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Editar </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="pdfItem" v-if="pdf">
        <v-list-item-icon>
          <v-icon color="info" small right> mdi-file-pdf-box </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Generar PDF </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        @click="enableItem"
        v-if="item.sadm_eliminado && !noUseEnableOrDisable"
      >
        <v-list-item-icon>
          <v-icon color="green" small right> mdi-delete-off </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Habilitar </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        @click="disableItem"
        v-else-if="!item.sadm_eliminado && !noUseEnableOrDisable"
      >
        <v-list-item-icon>
          <v-icon color="red" small right> mdi-delete </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ textForDisabledItem || "Deshabilitar" }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    item: Object,
    view: Boolean,
    pdf: Boolean,
    noUseEnableOrDisable: Boolean,
    noUseEdit: Boolean,
    textForDisabledItem: String,
  },
  methods: {
    editItem() {
      this.$emit("edit-item", this.item);
    },
    pdfItem() {
      this.$emit("pdf-item", this.item);
    },
    enableItem() {
      this.$emit("enable-item", this.item);
    },
    disableItem() {
      this.$emit("disable-item", this.item);
    },
    viewItem() {
      this.$emit("view-item", this.item);
    },
  },
};
</script>
