<template>
  <div style="display: inline-flex">
    <v-tooltip bottom v-if="$vuetify.breakpoint.mdAndUp">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="support lighten-1"
          outlined
          class="mt-2 py-5 py-sm-0"
          :loading="loading"
          @click="handleSync"
        >
          <v-icon :left="$vuetify.breakpoint.xs">mdi-refresh</v-icon>
          <span v-if="$vuetify.breakpoint.xs"> Sincronizar</span>
        </v-btn>
      </template>
      <span> Sincronizar</span>
    </v-tooltip>
    <v-btn
      v-else
      color="support lighten-1"
      outlined
      class="mt-2 py-5 py-sm-0"
      :loading="loading"
      @click="handleSync"
    >
      <v-icon :left="$vuetify.breakpoint.xs">mdi-refresh</v-icon>
      <span v-if="$vuetify.breakpoint.xs"> Sincronizar</span>
    </v-btn>
  </div>
</template>
<script>
export default {
  name: "SyncInfo",
  props: {
    title: String,
    Model: String,
    id: String,
    loading: Boolean,
  },
  data() {
    return {
      dialog: false,
      loadingSync: false,
    };
  },
  methods: {
    handleSync() {
      this.$store.dispatch(this.id, true);
    },
  },
};
</script>

<style scoped></style>
