import { render, staticRenderFns } from "./TProcesos.vue?vue&type=template&id=00deffb8&scoped=true&"
import script from "./TProcesos.vue?vue&type=script&lang=js&"
export * from "./TProcesos.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/globals.css?vue&type=style&index=0&id=00deffb8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00deffb8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VDataTable,VIcon,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VRow})
