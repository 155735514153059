<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card class="pa-2">
      <v-card-title class="text-h6 primary--text">
        <v-icon large color="hederColor" class="pr-2">
          mdi-alert-circle-outline</v-icon
        >
        {{ title }}
      </v-card-title>
      <v-card-text v-if="text">
        <p>
          {{ text }}
        </p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeDelete" :loading="loadingSave" color="support" text>
          {{ textBtnCancel || "volver" }}
        </v-btn>
        <v-btn
          @click="changeStateItemConfirm"
          :loading="loadingSave"
          outlined
          color="primary"
        >
          {{ textBtnAcept || "si, continuar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: String,
    text: String,
    textBtnAcept: String,
    textBtnCancel: String,
    loadingSave: Boolean,
    dialogDelete: Boolean,
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    changeStateItemConfirm() {
      this.$emit("change-state");
    },
    closeDelete() {
      this.$emit("close-delete");
    },
  },
  watch: {
    dialog(value) {
      this.$emit("dialog-delete", value);
    },
    dialogDelete(val) {
      this.dialog = val;
    },
  },
};
</script>
