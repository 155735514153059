var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('my-header-view',{attrs:{"title":"Flujo de procesos","bread-crumbs":_vm.breadCrums}}),(_vm.loading)?_c('my-loader',{attrs:{"title":"Un momento por favor","subtitle":"Estamos obteniendo los procesos existentes."}}):_c('v-row',{staticClass:"pt-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0 mt-5 soft_shadow px-4",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"items-per-page":5,"header-props":{ 'sort-by-text': 'Ordenar por' },"footer-props":{
          'items-per-page-text': 'Procesos por página',
          'items-per-page-all-text': 'Todos',
        }},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('my-table-top',{attrs:{"enableSearch":""},on:{"handleSearch":_vm.handleSearch},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('sync-info',{attrs:{"title":"los procesos","loading":_vm.loading,"id":"tprocesos/findProcesos"}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('my-table-options',{attrs:{"include-create":"","include-report":"","create-text":"Nuevo proceso"},on:{"optionReport":_vm.createReport,"optionCreate":_vm.openCreateForm},scopedSlots:_vm._u([{key:"itemOptions",fn:function(){return [_c('v-list-item',{on:{"click":_vm.toSort}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-sort ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Orden del flujo ")])],1)],1)]},proxy:true}])})],1)],1)]},proxy:true}])})]},proxy:true},{key:"no-data",fn:function(){return [_c('my-table-no-data',{attrs:{"title":"¡Lo sentimos!","text":"Aun no existe algun proceso.","button":"","button-text":"Agregar"},on:{"button-data-action":_vm.openCreateForm}})]},proxy:true},{key:"no-results",fn:function(){return [_c('my-table-no-data',{attrs:{"title":"¡Lo sentimos!","text":"No encontramos ningun proceso."}})]},proxy:true},{key:"item.sadm_eliminado",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.eliminado ? "Eliminado" : "Activo")+" ")]}},{key:"item.sadm_fecha_creado",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.milisecondsToDate(item.sadm_fecha_creado))+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('my-table-item-actions',{attrs:{"item":item},on:{"edit-item":_vm.editItem,"enable-item":_vm.enableItem,"disable-item":_vm.disableItem}})]}}],null,true)})],1)],1),(_vm.dialogForm)?_c('procesos-form',{attrs:{"itemIndex":_vm.editedIndex,"itemParam":_vm.editedItem},on:{"close":_vm.close}}):_vm._e(),_c('my-table-dialog-delete',{attrs:{"title":_vm.updateUserTitle,"loadingSave":_vm.loadingSave,"dialogDelete":_vm.dialogDelete},on:{"change-state":_vm.changeStateItemConfirm,"close-delete":_vm.closeDelete,"dialog-delete":function (val) { return (_vm.dialogDelete = val); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }