<template>
  <v-dialog v-model="dialog" max-width="400px" persistent>
    <v-card>
      <v-card-title class="secondary">
        <v-icon color="pla_c3"> {{ iconTitle }} </v-icon>
        <span class="text-h6 pl-3 white--text">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text class="py-2 px-5">
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  autofocus
                  label="Nombre"
                  hide-details="auto"
                  v-model="item.prprcNombre"
                  :rules="noEmptyRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-switch
                  v-model="item.prprcSubProceso"
                  inset
                  label="Es un subproceso"
                ></v-switch>
              </v-col>
              <v-col cols="12">
                <v-switch
                  v-model="item.prprcCreaProductoNuevo"
                  inset
                  label="Genera producto nuevo"
                ></v-switch>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  readonly
                  label="orden (automatico)"
                  hint="puedes modificarlo tras la creación"
                  persistent-hint
                  v-model="item.prprcOrdenProceso"
                ></v-text-field>
                <p>(AUN EN DESARROLLO)</p>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-5">
        <v-spacer></v-spacer>
        <v-btn @click="close" :loading="loadingSave" color="support" outlined>
          Cancelar
        </v-btn>
        <v-btn
          @click="save"
          elevation="0"
          :loading="loadingSave"
          color="primary"
        >
          {{ buttonTitle }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    itemIndex: Number,
    itemParam: Object,
  },
  data: () => ({
    valid: false,
    item: {
      prprcNombre: "",
      prprcSubProceso: false,
      prprcCreaProductoNuevo: false,
      prprcOrdenProceso: 1,
    },
    noEmptyRules: [(value) => !!value || "Completa este dato."],
    dialog: true,
  }),
  methods: {
    save() {
      if (!this.$refs.form.validate()) return;
      if (this.itemIndex > -1) {
        this.updateItem();
      } else {
        this.createItem();
      }
    },
    createItem() {
      this.$store
        .dispatch("tprocesos/createProceso", {
          data: this.item,
        })
        .then(() => this.close())
        .catch((error) => console.log(error));
    },
    updateItem() {
      const data = {
        prprcNombre: this.item.prprcNombre,
        prprcSubProceso: this.item.prprcSubProceso,
        prprcCreaProductoNuevo: this.item.prprcCreaProductoNuevo,
      };
      this.$store
        .dispatch("tprocesos/updateProceso", {
          data: data,
          prprcId: this.item.prprcId,
        })
        .then(() => this.close())
        .catch((error) => console.log(error));
    },
    close() {
      this.$emit("close");
    },
  },
  mounted() {
    this.item = this.itemParam;
  },
  computed: {
    ...mapState({
      loadingSave: (state) => state.tprocesos.loadingSave,
    }),
    formTitle() {
      return this.itemIndex === -1 ? "Nuevo proceso" : "Editar proceso";
    },
    buttonTitle() {
      return this.itemIndex === -1 ? "Guardar" : "Actualizar";
    },
    iconTitle() {
      return this.itemIndex === -1 ? "mdi-plus" : "mdi-pen";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
};
</script>
