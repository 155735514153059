<template>
  <v-row class="pt-7" justify="center" align="center">
    <v-col cols="12">
      <v-card flat class="soft_shadow pa-15">
        <v-row class="" no-gutters>
          <v-col
            cols="12"
            class="pt-15 no-gutters"
            align="center"
            justify="center"
          >
            <!--            <div :class="inDialog ? 'img-loader-dialog' : 'img-loader'">
              <v-img
                contain
                max-height="40"
                max-width="40"
                :src="'/img/icons/apple-touch-icon-180x180.png'"
              ></v-img>
            </div>
            <v-progress-circular
              :size="120"
              :width="3"
              color="pla_c3"
              indeterminate
            ></v-progress-circular>-->
            <div class="fulfilling-bouncing-circle-spinner my-5">
              <div class="circle"></div>
              <div class="orbit"></div>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="pb-15 pt-4 grey--text text--darken-3"
            align="center"
            justify="center"
          >
            <h2 class="primary--text text-h6 text-md-h5 font-weight-bold">
              {{ title }}
            </h2>
            <small class="font-weight-medium">{{ subtitle }}</small>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "myLoader",
  props: {
    title: String,
    subtitle: String,
  },
};
</script>
<style scoped src="@/assets/css/globals.css"></style>
<style scoped>
.fulfilling-bouncing-circle-spinner,
.fulfilling-bouncing-circle-spinner * {
  box-sizing: border-box;
}

.fulfilling-bouncing-circle-spinner {
  height: 60px;
  width: 60px;
  position: relative;
  animation: fulfilling-bouncing-circle-spinner-animation infinite 4000ms ease;
}

.fulfilling-bouncing-circle-spinner .orbit {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  border: calc(60px * 0.03) solid #f6d316;
  animation: fulfilling-bouncing-circle-spinner-orbit-animation infinite 4000ms
    ease;
}

.fulfilling-bouncing-circle-spinner .circle {
  height: 60px;
  width: 60px;
  color: #f6d316;
  display: block;
  border-radius: 50%;
  position: relative;
  border: calc(60px * 0.1) solid #f6d316;
  animation: fulfilling-bouncing-circle-spinner-circle-animation infinite 4000ms
    ease;
  transform: rotate(0deg) scale(1);
}

@keyframes fulfilling-bouncing-circle-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fulfilling-bouncing-circle-spinner-orbit-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1);
  }
  62.5% {
    transform: scale(0.8);
  }
  75% {
    transform: scale(1);
  }
  87.5% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fulfilling-bouncing-circle-spinner-circle-animation {
  0% {
    transform: scale(1);
    border-color: transparent;
    border-top-color: inherit;
  }
  16.7% {
    border-color: transparent;
    border-top-color: initial;
    border-right-color: initial;
  }
  33.4% {
    border-color: transparent;
    border-top-color: inherit;
    border-right-color: inherit;
    border-bottom-color: inherit;
  }
  50% {
    border-color: inherit;
    transform: scale(1);
  }
  62.5% {
    border-color: inherit;
    transform: scale(1.4);
  }
  75% {
    border-color: inherit;
    transform: scale(1);
    opacity: 1;
  }
  87.5% {
    border-color: inherit;
    transform: scale(1.4);
  }
  100% {
    border-color: transparent;
    border-top-color: inherit;
    transform: scale(1);
  }
}
</style>
