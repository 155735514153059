<template>
  <v-row class="py-15" no-gutters>
    <v-col cols="12" class="p-0 m-0 pt-15" align="center" justify="center">
      <v-icon style="font-size: 3em"> mdi-folder-information-outline </v-icon>
    </v-col>
    <v-col
      cols="12"
      class="p-0 grey--text text--darken-3"
      align="center"
      justify="center"
    >
      <h2 class="primary--text">{{ title }}</h2>
      <small class="font-weight-medium">{{ text }}</small>
    </v-col>
    <v-col v-if="button" cols="12" align="center" class="pt-3">
      <v-btn outlined color="support" @click="press">
        {{ buttonText }}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "mplaTableNoData",
  props: {
    title: String,
    text: String,
    button: Boolean,
    buttonText: String,
  },
  methods: {
    press() {
      this.$emit("button-data-action");
    },
  },
};
</script>

<style scoped></style>
