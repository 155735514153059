<template>
  <div>
    <v-menu transition="slide-y-transition" bottom left offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="py-sm-0"
          :class="[block ? 'ml-0 mt-0' : 'ml-4 mt-2 py-5 mt-sm-2']"
          color="secondary"
          v-bind="attrs"
          v-on="on"
          elevation="0"
          :block="block"
        >
          opciones
        </v-btn>
      </template>
      <v-list dense nav>
        <v-list-item v-if="includeCreate" @click="create">
          <v-list-item-icon>
            <v-icon color="primary"> mdi-plus </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ createText || "Nuevo" }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="includeReport" @click="createReport">
          <v-list-item-icon>
            <v-icon color="primary"> mdi-microsoft-excel </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ reportText || "Exportar a excel" }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="includePdf" @click="createPdf">
          <v-list-item-icon>
            <v-icon color="primary"> mdi-file-pdf-box </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ pdfText || "Boletas a PDF" }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <slot name="itemOptions"></slot>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: "myTableOptions",
  props: {
    includeCreate: Boolean,
    createText: String,
    includeReport: Boolean,
    includePdf: Boolean,
    reportText: String,
    pdfText: String,
    block: Boolean,
  },
  methods: {
    createReport() {
      this.$emit("optionReport");
    },
    create() {
      this.$emit("optionCreate");
    },
    createPdf() {
      this.$emit("optionPdf");
    },
  },
};
</script>

<style scoped></style>
