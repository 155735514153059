<template>
  <v-row v-if="customTop" class="px-5">
    <slot name="custom"></slot>
  </v-row>
  <v-row v-else class="px-5">
    <v-col cols="12" :md="enableSync ? 8 : 10">
      <v-row>
        <v-col cols="12" md="6" v-if="enableSearch">
          <v-text-field
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            outlined
            dense
            class="pt-2"
            v-model="searchFilter"
            @keyup="emitSearch()"
          ></v-text-field>
        </v-col>
        <slot name="extra-filters"></slot>
      </v-row>
    </v-col>
    <v-col cols="12" :md="enableSync ? 4 : 2" align="right">
      <slot name="options"></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "myTopTable",
  props: {
    enableSearch: Boolean,
    customTop: Boolean,
    enableSync: Boolean,
  },
  data: () => ({
    searchFilter: "",
  }),
  methods: {
    emitSearch() {
      this.$emit("handleSearch", this.searchFilter);
    },
  },
};
</script>

<style scoped></style>
