<template>
  <div class="">
    <my-header-view title="Flujo de procesos" :bread-crumbs="breadCrums" />
    <my-loader
      v-if="loading"
      title="Un momento por favor"
      subtitle="Estamos obteniendo los procesos existentes."
    />
    <v-row v-else class="pt-5">
      <v-col cols="12" class="">
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          @click:row="handleClick"
          :items-per-page="5"
          :header-props="{ 'sort-by-text': 'Ordenar por' }"
          :footer-props="{
            'items-per-page-text': 'Procesos por página',
            'items-per-page-all-text': 'Todos',
          }"
          class="elevation-0 mt-5 soft_shadow px-4"
        >
          <!-- slots defaults -->
          <template v-slot:top>
            <my-table-top enableSearch @handleSearch="handleSearch">
              <template v-slot:options>
                <v-row no-gutters>
                  <v-col cols="4">
                    <sync-info
                      title="los procesos"
                      :loading="loading"
                      id="tprocesos/findProcesos"
                    />
                  </v-col>
                  <v-col cols="8">
                    <my-table-options
                      include-create
                      include-report
                      create-text="Nuevo proceso"
                      @optionReport="createReport"
                      @optionCreate="openCreateForm"
                    >
                      <template v-slot:itemOptions>
                        <v-list-item @click="toSort">
                          <v-list-item-icon>
                            <v-icon color="primary"> mdi-sort </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              Orden del flujo
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </my-table-options>
                  </v-col>
                </v-row>
              </template>
            </my-table-top>
          </template>
          <template v-slot:no-data>
            <my-table-no-data
              title="¡Lo sentimos!"
              text="Aun no existe algun proceso."
              button
              button-text="Agregar"
              @button-data-action="openCreateForm"
            />
          </template>
          <template v-slot:no-results>
            <my-table-no-data
              title="¡Lo sentimos!"
              text="No encontramos ningun proceso."
            />
          </template>
          <!-- slots defaults end -->
          <template v-slot:[`item.sadm_eliminado`]="{ item }">
            {{ item.eliminado ? "Eliminado" : "Activo" }}
          </template>
          <template v-slot:[`item.sadm_fecha_creado`]="{ item }">
            {{ milisecondsToDate(item.sadm_fecha_creado) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <my-table-item-actions
              :item="item"
              @edit-item="editItem"
              @enable-item="enableItem"
              @disable-item="disableItem"
            >
            </my-table-item-actions>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <procesos-form
      v-if="dialogForm"
      :itemIndex="editedIndex"
      :itemParam="editedItem"
      @close="close"
    >
    </procesos-form>

    <my-table-dialog-delete
      :title="updateUserTitle"
      :loadingSave="loadingSave"
      :dialogDelete="dialogDelete"
      @change-state="changeStateItemConfirm"
      @close-delete="closeDelete"
      @dialog-delete="(val) => (dialogDelete = val)"
    ></my-table-dialog-delete>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { milisecondsToDate, catalogReport } from "@/core/utils";
import ProcesosForm from "@/components/Forms/TProcesosForm";
import myHeaderView from "../../ui/myHeaderView";
import myLoader from "../../ui/myLoader";
import myTableTop from "../../ui/datatable/myTableTop";
import myTableNoData from "../../ui/datatable/myTableNoData";
import myTableOptions from "../../ui/datatable/myTableOptions";
import myTableItemActions from "../../ui/datatable/myTableItemActions";
import myTableDialogDelete from "../../ui/datatable/myTableDialogDelete";
import SyncInfo from "@/components/Home/SyncInfo";

export default {
  components: {
    ProcesosForm,
    myHeaderView,
    myLoader,
    myTableOptions,
    myTableDialogDelete,
    myTableNoData,
    myTableItemActions,
    myTableTop,
    SyncInfo,
  },
  name: "procesos",
  data: () => ({
    breadCrums: [
      {
        text: "Inicio",
        disabled: false,
        url: "Home",
      },
      {
        text: "Configuración",
        disabled: false,
        url: "Configuracion",
      },
      {
        text: "Flujo de procesos",
        disabled: true,
        url: "TProcesos",
      },
    ],
    dialogForm: false,
    dialogDelete: false,
    headers: [
      { text: "Nombre", value: "prprcNombre" },
      { text: "Estado", value: "sadm_eliminado" },
      { text: "Creación", value: "sadm_fecha_creado" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      prprcNombre: "",
      prprcSubProceso: false,
      prprcCreaProductoNuevo: false,
      prprcOrdenProceso: 1,
    },
    defaultItem: {
      prprcNombre: "",
      prprcSubProceso: false,
      prprcCreaProductoNuevo: false,
      prprcOrdenProceso: 1,
    },
    estado: 0,
    search: "",
  }),
  computed: {
    ...mapState({
      items: (state) => state.tprocesos.procesos,
      loading: (state) => state.tprocesos.loading,
      loadingSave: (state) => state.tprocesos.loadingSave,
    }),
    updateUserTitle() {
      return this.estado === 0
        ? "¿Desea activar este proceso?"
        : "¿Desea desactivar este proceso?";
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    toSort() {
      this.$router.push({
        name: "flujoProcesOrden",
      });
    },
    handleClick(row) {
      localStorage.setItem(`prprcNombre`, row.prprcNombre);
      localStorage.setItem(`prprcId`, row.prprcId);
      this.$router.push({
        name: "ProdProcess",
        params: { id: row.prprcId },
      });
    },
    openCreateForm() {
      this.dialogForm = true;
    },
    handleSearch(value) {
      this.search = value;
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.openCreateForm();
    },

    close() {
      this.dialogForm = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    enableItem(item) {
      this.estado = 0;
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    disableItem(item) {
      this.estado = 1;
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    changeStateItemConfirm() {
      let data = {
        prprcId: this.editedItem.prprcId,
        estado: this.estado === 1,
      };
      this.$store
        .dispatch("tprocesos/deleteProceso", {
          data,
        })
        .then(() => (this.dialogDelete = false))
        .catch((error) => console.log(error));
    },

    milisecondsToDate(miliseconds) {
      return milisecondsToDate(miliseconds);
    },

    createReport() {
      const params = {
        name: "Tipos de procesos",
        moneda: "GTQ",
        columns: [
          { header: "Nombre", key: "nombre", width: 18 }, //TODO set width
          { header: "Estado", key: "estado", width: 12 },
          { header: "Creación", key: "creacion", width: 12 },
          { header: "Modificacion", key: "modificacion", width: 12 },
        ],
        data: this.getData(),
      };
      catalogReport(params);
    },

    getData() {
      return this.items.map((item) => ({
        nombre: {
          value: item.prprcNombre,
          type: "text",
          column: "A",
        },
        estado: {
          value: item.sadm_eliminado ? "Eliminado" : "Activo",
          type: "text",
          column: "B",
        },
        creacion: {
          value: milisecondsToDate(item.sadm_fecha_creado),
          type: "date",
          column: "C",
        },
        modificacion: {
          value: milisecondsToDate(item.sadm_fecha_modificado),
          type: "date",
          column: "D",
        },
      }));
    },
  },

  mounted() {
    this.$store.dispatch("tprocesos/findProcesos");
  },
};
</script>
<style scoped src="@/assets/css/globals.css"></style>
